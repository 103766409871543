@font-face {
    font-family: math;
    src: url('https://mathjax.schuelerhilfe-online.de/static_test/fonts/MathJax_Main-Regular.woff') format('woff');
}

@font-face {
    font-family: 'NotoSans';
    src: url('https://mathjax.schuelerhilfe-online.de/static_test/fonts/notosans-bold-webfont.eot');
    src: url('https://mathjax.schuelerhilfe-online.de/static_test/fonts/notosans-bold-webfont.eot?#iefix') format('embedded-opentype'),
    url('https://mathjax.schuelerhilfe-online.de/static_test/fonts/notosans-bold-webfont.woff2') format('woff2'),
    url('https://mathjax.schuelerhilfe-online.de/static_test/fonts/notosans-bold-webfont.woff') format('woff'),
    url('https://mathjax.schuelerhilfe-online.de/static_test/fonts/notosans-bold-webfont.svg#noto_sansbold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'NotoSans';
    src: url('https://mathjax.schuelerhilfe-online.de/static_test/fonts/notosans-bolditalic-webfont.eot');
    src: url('https://mathjax.schuelerhilfe-online.de/static_test/fonts/notosans-bolditalic-webfont.eot?#iefix') format('embedded-opentype'),
    url('https://mathjax.schuelerhilfe-online.de/static_test/fonts/notosans-bolditalic-webfont.woff2') format('woff2'),
    url('https://mathjax.schuelerhilfe-online.de/static_test/fonts/notosans-bolditalic-webfont.woff') format('woff'),
    url('https://mathjax.schuelerhilfe-online.de/static_test/fonts/notosans-bolditalic-webfont.svg#noto_sansbold_italic') format('svg');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'NotoSans';
    src: url('https://mathjax.schuelerhilfe-online.de/static_test/fonts/notosans-italic-webfont.eot');
    src: url('https://mathjax.schuelerhilfe-online.de/static_test/fonts/notosans-italic-webfont.eot?#iefix') format('embedded-opentype'),
    url('https://mathjax.schuelerhilfe-online.de/static_test/fonts/notosans-italic-webfont.woff2') format('woff2'),
    url('https://mathjax.schuelerhilfe-online.de/static_test/fonts/notosans-italic-webfont.woff') format('woff'),
    url('https://mathjax.schuelerhilfe-online.de/static_test/fonts/notosans-italic-webfont.svg#noto_sansitalic') format('svg');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'NotoSans';
    src: url('https://mathjax.schuelerhilfe-online.de/static_test/fonts/notosans-regular-webfont.eot');
    src: url('https://mathjax.schuelerhilfe-online.de/static_test/fonts/notosans-regular-webfont.eot?#iefix') format('embedded-opentype'),
    url('https://mathjax.schuelerhilfe-online.de/static_test/fonts/notosans-regular-webfont.woff2') format('woff2'),
    url('https://mathjax.schuelerhilfe-online.de/static_test/fonts/notosans-regular-webfont.woff') format('woff'),
    url('https://mathjax.schuelerhilfe-online.de/static_test/fonts/notosans-regular-webfont.svg#noto_sansregular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'montserratmedium';
    src: url('https://mathjax.schuelerhilfe-online.de/static_test/fonts/montserrat-medium-webfont.woff2') format('woff2'),
         url('https://mathjax.schuelerhilfe-online.de/static_test/fonts/montserrat-medium-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'montserratregular';
    src: url('https://mathjax.schuelerhilfe-online.de/static_test/fonts/montserrat-regular-webfont.woff2') format('woff2'),
         url('https://mathjax.schuelerhilfe-online.de/static_test/fonts/montserrat-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
