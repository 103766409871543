@import url(./utils/fonts/fonts.css);

:root {
  --sh-blue: #004f9f;
  --sh-blue-background-hover: #004080;
  --sh-blue-background-active: #3373b3;

  --sh-light-gray: #e1e1e1;
  --sh-gray: #a79f96;

  --sh-black: #28190a;

  --sh-brown-for-hover: #74685b;

  --sh-beige-background: #faf5eb;
  --sh-beige-background-hover: #ede8e1;
  --sh-beige-backgound-active: #d1cbbf;

  --sh-beige-background-dark: #f2ece2;

  /* Button */
  --sh-button-blue: #004f9f;
  --sh-button-beige: #faf5eb;
  --sh-button-beige-hover: #ede8e1;
  --sh-button-beige-active: #d1cbbf;
  --sh-button-blue-hover: #004080;
  --sh-button-blue-active: #3373b3;
  --sh-button-beige-background-disabled: #ccc8c4;

  /* Input */
  --sh-text-input-label: #74685b;
  --sh-input-background: #e1dbd1;

  --sh-input-error-label: #a94442;
  --sh-input-error-border: #bd716e;

  --sh-text-input-gray-label: #c3bfbb;
  --sh-text-input-gray-background: #eae8e7;
  --sh-text-input-gray-focus: #c3bfbb;
}

html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  font-size: 16px;
  font-family: -apple-system, BlinkMacSystemFont, "montserratmedium", "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "NotoSans",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.rootDiv {
  height: 100%;
}

.requestPasswordResetMailTo {
  color: var(--sh-blue);
  font-style: italic;
  text-decoration: underline;
}

/* Youtube Video stylings */
._2hdkJ {
  border-radius: 1rem !important;
}

._16Q0f {
  border-radius: 1rem !important;
}
